import React from 'react'
import ChloeT from "./headshots/chloet.svg";

function chloeT(props) {
    return (
        <div>
            <h1 className="chloet-header" >Chloe T</h1> 
           <img src={ChloeT} alt="Chloe T's headshot"/>
        </div>
    )
}


export default chloeT

