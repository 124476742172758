import React from 'react'
import Sofia from "./headshots/sofia.svg";

function sofia(props) {
    return (
        <div>
            <h1 className="sofia-header">Sofia</h1> 
           <img src={Sofia} alt="Sofia's headshot"/>
        </div>
    )
}


export default sofia

